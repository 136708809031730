.funFactsBanner {
    margin: 10% 20px;
}
.planetoid {
    position: relative;
    height: 20rem;
    margin-left: -10rem;
    margin-top: -10rem;
    z-index: -1;
}
.funFactsHeader {
    font-weight: 600;
    font-family: Oswald;
    letter-spacing: 2px;
}
.funFactsUl {
    margin: 0;
    padding: 0;
}
.funFactsLi {
    margin: 15px 20px;
}
.innovationHeader {
    text-align: center;
    margin: 4rem auto;
}
.planetoidRight {
    position: absolute;
    height: 12rem;
    right: 0;
    z-index: -1;
    bottom: -165%;
}
@media (min-width: 600px)  {
    .funFactsCol {
        margin-right: 30%;
        margin-bottom: 4rem;
    }
    .firstsCol {
        margin-left: 30%;
    }
}
@media(min-width: 768px) {
    .innovationHeader {
        margin: 8rem auto;
    }
    .funFactsBanner {
        margin: 10%;
    }
    .funFactsCol {
        margin-right: 2rem;
        margin-bottom: 2rem;
    }
    .firstsCol {
        margin-left: 2rem;
    }
}
