.CTOLanding {
    padding: 100px 0px;
    z-index: 1;
    background: linear-gradient(335deg, rgba(0, 204, 165, 0.20) 8.42%, rgba(29, 105, 159, 0.20) 32.15%, rgba(86, 7, 153, 0.20) 55.69%);
    margin-bottom: 0;
}
.pageHeader {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #00CCA5;
    padding: 0px 30px 0px 30px;
}
.pageBlurb {
    padding: 0px 30px 30px 30px;
    margin-bottom: 10px;
}
.subHeader {
    color: #00CCA5;
    text-transform: uppercase;
    font-family: Oswald;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 1px;
    padding: 0px 30px 15px 30px;
}
.funkyBoxSection {
    margin: 0px 0px 50px 0px;
}
.funkyBoxRow {
    gap: 10px; 
    display: flex;
    flex-wrap: wrap; /* Ensure wrapping for smaller screens */
    margin: 0 auto; /* Center-align content */
    padding: 0px 30px 0px 30px;
}

.funkyBox {
    width: 100%;
    position: relative;
    padding: 15px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    z-index: 1;
    color: white;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
.funkyBox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 10px;
    background: linear-gradient(90deg, #00ffea, #7f00ff);
    padding: 3px; /* Adjust thickness of gradient */
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude;
}

.funkyBoxHeader {
    font-family: Oswald;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 1px;
}
.funkyBoxBlurb {
    font-family: Avenir;
    font-weight: 300;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: .5px;
    margin-bottom: 0;
}


/* background */
.planetoid {
    margin-left: -10rem;
    margin-top: 17rem;
    z-index: -1;
    display: block;
    position: absolute;
    opacity: .5;
}

.ctoAstronautRight {
    width: 100%;
    float: right;
    padding: 0;
}

/* CTA Button */

.CTAButton {
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: Oswald;
    text-transform: uppercase;
    position: fixed;
    left: 0; /* Align to the left edge of the viewport */
    right: 0; /* Align to the right edge of the viewport */
    animation: fadeIn 1s;
    transition: opacity 1s;
    bottom: 25px;
    z-index: 10;
    padding: 12px;
    border-radius: 10px;
    border: none;
    background: linear-gradient(90deg, #00ffea, #00adcb, #7f00ff);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.547);
    text-align: center;
    cursor: pointer;
    margin: 0 auto;
    max-width: 400px; /* Prevents the button from being too wide */
    width: 90%; /* Adjusts button width for smaller screens */
}
.CTAButton:hover{
    opacity: 1;
}
.weeklySection {
    margin: 0;
}
.monthlyRowSmall {
    margin-bottom: 10px;
}
.weeklyBoxRow {
    margin-bottom: 10px;
}
.monthlySectionSmall {
    display: block;
}
.monthlySectionLarge {
    display: none;
}
/* Media Queries */
@media(min-width: 510px) {
    .funkyBoxRow {
        gap: 20px; 
    }
    .funkyBox {
        padding: 20px;
    }
    .funkyBoxHeader {
        font-size: 20px;
    }
    .funkyBoxyBlurb {
        font-size: 16px;
    }
    .weeklyBoxRow {
        margin-bottom: 20px;
    }
    .monthlyRowSmall {
        margin-bottom: 20px;
    }
}

@media (min-width: 728px) {
    .monthlyBox, .weeklyBox {
        flex: 1 1 0;
    }
}

@media (min-width: 768px) {
    .consultBox, .adhocBox {
        flex: 1 1 0;
    }
    .subHeader {
        padding: 0px 0 10px 0;
    }
    .funkyBoxRow {
        padding: 0;
    }
    .pageHeader, .pageBlurb, .funkyBoxSection {
        padding: 0px 9%;
    }
    .weeklyBoxRowTwo {
        padding: 0 6.5% 0 13.5%;
    }
    .adhocSection {
        padding: 0 6.5% 0 13.5%;
    }
    .funkyBoxBlurb {
        font-size: 16px;
    }
    .pageBlurb {
        font-size: 16px;
        margin-bottom: 30px;
    }
}

@media (min-width: 915px) {
   .planetoid {
        height: 50rem;   
    }
}

@media (min-width: 1024px) {
    .monthlySectionSmall {
        display: none;
    }
    .monthlySectionLarge {
        display: block;
    }
    .pageHeader, .pageBlurb, .funkyBoxSection {
        padding: 0px 7%;
    }
    .weeklyBoxRowTwo {
        padding: 0 4.5% 0 10.5%;
    }
    .adhocSection {
        padding: 0 4.5% 0 10.5%;
    }
}

