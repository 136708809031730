

.privacyPolicyRow {
    padding: 6rem 20px 5rem 20px;
    line-height: 1.6;
}
.privacyPolicyRow h1 {
    letter-spacing: 5px;
}
.privacyPolicyRow h2 {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 2px;
}
.privacyPolicyRow h3 {
    margin-top: 1.5rem;
    font-size: 1.5rem;
}
.privacyPolicyRow h4 {
    margin-top: 1rem;
    font-size: 1.25rem;
}
.privacyPolicyRow h5 {
    font-family: Avenir;
    font-size: 1rem;
    letter-spacing: 1px;
}
.privacyPolicyRow p {
    letter-spacing: 1px;
}

.privacyPolicyRow a {
    color: #007bff;
    text-decoration: none;
}

.privacyPolicyRow a:hover {
    text-decoration: underline;
}


@media (min-width: 768px) {
    .privacyPolicyRow {
        padding: 6rem 9%;
    }  
    
}
@media (min-width: 1024px) {
    .privacyPolicyRow {
        padding: 6rem 6%;
    }
}