.heroBanner {
    margin: 20% 10% 15% 10%;
    position: relative;
    z-index: 1;
}
.astronaut {
    display: none;
}
.astronautRight {
    width: 15rem;
    float: right;
}
.planetoidOne {
    height: 10rem;
    margin-left: -5rem;
    margin-top: 3rem;
    z-index: 1000;
}
@media (min-width: 700px) {
    .heroBanner {
        margin: 20% 10% 0% 10%;
    }
    .astronautRight {
        margin-top: -5rem;
    }
}
@media (min-width: 800px) {
    .heroBanner {
        margin: 15% 10% -5% 10%;
    }
    .heroBlurb {
        margin-right: 30%;
    }
    .astronautRight {
        margin-top: -2rem;
    }
}
@media (min-width: 1200px) {
    .heroBanner {
        margin: 10% 20% 15% 25%;
    }
    .astronautRight {
        display: none;
    }
    .astronaut {
        display: block;
        width: 30rem;
        position: fixed;
        left: -10rem;
        transition: all 5s ease;
        z-index: 0;
    }
    .heroDiv {
        position: fixed;
        transition: all 5s ease;
    }
    .featuredDiv {
        width: 25rem;
        left: 65vw;
        transform: rotate(-45deg);
        transition: all 5s ease-in-out;
    }
    .capabilitiesDiv {
        width: 20rem;
        left: 0;
        transform: rotate(0) translateY(10rem);
        transition: all 5s ease-in-out;
    }
    .projectsDiv {
        width: 25rem;
        left: -40rem;
        transform: rotate(0) translateY(15rem);
        transition: all 5s ease-in-out;
    }
    .funFactsDiv {
        width: 15rem;
        left: 35vw;
        transform: rotate(-45deg) translateY(10rem);
        transition: all 5s ease-in-out;
    }
    .meetCrewDiv {
        width: 20rem;
        left: 86vw;
        transform: rotate(-45deg);
        transition: all 5s ease-in-out;
    }
    .footerDiv {
        width: 45rem;
        left: 65vw;
        transform: rotate(-45deg);
        transition: all 5s ease-in-out;
    }
}
