.modalTextLeft { 
    margin: 0 4rem 0px 0px;
    text-align: center;
}
body {
    scrollbar-color: transparent transparent;
}
/*Chrome Scrollbar*/
.modalTextLeft::-webkit-scrollbar{
    width: 5px;
    height: 0px;
}
.modalTextLeft::-webkit-scrollbar-track-piece  {
    background-color: transparent;
}

.bestBuyScrolling::-webkit-scrollbar-thumb:vertical,
.hiRoadScrolling::-webkit-scrollbar-thumb:vertical,
.crbScrolling::-webkit-scrollbar-thumb:vertical,
.letsbloomScrolling::-webkit-scrollbar-thumb:vertical,
.aaaScrolling::-webkit-scrollbar-thumb:vertical
 {
    background-color: transparent;
}
.bestBuyScrolling:hover::-webkit-scrollbar-thumb:vertical {
    background-color: #FFED39;
    border-radius: 10px;
}
.hiRoadScrolling:hover::-webkit-scrollbar-thumb:vertical {
    background-color: #009822;
    border-radius: 10px;
}
.crbScrolling:hover::-webkit-scrollbar-thumb:vertical {
    background-color: #C71E2D;
    border-radius: 10px;
}
.letsbloomScrolling:hover::-webkit-scrollbar-thumb:vertical {
    background-color: #230952;
    border-radius: 10px;
}
.aaaScrolling:hover::-webkit-scrollbar-thumb:vertical {
    background-color: white;
    border-radius: 10px;

}

/*Firefox Scrollbar*/
.bestBuyScrolling {
    scrollbar-color: #FFED39 transparent;
    border-radius: 10px;
}
.hiRoadScrolling {
    scrollbar-color: #009822 transparent;
    border-radius: 10px;
}
.crbScrolling {
    scrollbar-color: #C71E2D transparent;
    border-radius: 10px;
}
.letsbloomScrolling {
    scrollbar-color: #230952 transparent;
    border-radius: 10px;
}
.aaaScrolling {
    scrollbar-color: white transparent;
    border-radius: 10px;
}
 

/*best buy*/
.bestBuyHeader {
    display: none;
}
.bestBuyLogoHeader {
    text-align: center;
    margin-bottom: 3rem;
}
.bestBuyLogoRow {
    display: none;
}
.bestBuyPhone1 {
    height: 22rem;
    float: left;
    margin-bottom: 3rem;
}
.bestBuyPhone2 {
    height: 22rem;
    float: right;
    margin-right: 4rem;
}
.bestBuyPhone3 {
    height: 22rem;
    float: left;
    margin-top: 3rem;
}
.yellowSquare1 {
    animation-duration: 20s;
    animation-iteration-count: infinite;

    width: 10rem;
    position: absolute;
    left: 8rem;
    z-index: -1;
    top: 4rem;
}
.yellowSquare3 {
    animation-duration: 25s;
    animation-iteration-count: infinite;

    width: 150px;
    position: absolute;
    top: 12rem;
    left: 35%;
}
.yellowSquare4 {
    animation-duration: 18s;
    animation-iteration-count: infinite;

    width: 10rem;
    position: absolute;
    left: -1rem;
    bottom: -2rem;
    z-index: -1;
    top: 5rem;
}
.yellowSquare5 {
    animation-duration: 28s;
    animation-iteration-count: infinite;

    width: 13rem;
    position: absolute;
    left: 11rem;
    top: 6rem;
    z-index: -1;
    box-shadow: 0 0 10px #1e1e1e;
}
.bounce-2 {
    animation-name: bounce-2;
    animation-timing-function: ease;
}
@keyframes bounce-2 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-25px); }
    100% { transform: translateY(0); }
}


/* HiRoad */
.hiRoadMobileLogo {
    text-align: center;
    margin-bottom: 4rem;
}
.hiRoadDesktopLogo {
    display: none;
}
.hiRoadCircle {
    height: 600px;
    width: 600px;
    background-color: #00C9EB;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    animation: spin 20s linear infinite, moveRightToLeft 10s linear infinite;
    transition: all 1s ease;
    box-shadow: 1px 1px 10px black;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    display: none;
}

  @keyframes moveRightToLeft {
    0%   { transform: translatex(0px) translatey(0px); }
    100% {
        transform: translatex(0px) translatey(0px);
    }
    50% { 
        transform: translatex(-25px) translatey(25px);
    }
  }
.greenWave {
    width: 100%;
    z-index: -2;
}
.hiRoadPhone {
    height: 32rem;
    position: absolute;
    z-index: 1;
    left: 0;
}
.hiRoadSmallScreen {
    height: 9rem;
    float: left;
    position: absolute;
    z-index: 1;
    right: 4rem;
    top: 70%;
}

/*crb*/
.crbMobileLogo {
    width: 100%;
}
.crbDesktopLogo {
    display: none;
}
.crbPhone {
    height: 20rem;
    position: absolute;
    z-index: 1;
    left: 5%;
    top: 20%;
    border-radius: 10px;
    box-shadow: 0 0 10px black;
}
.crbSmallScreen {
    height: 10rem;
    position: absolute;
    z-index: 1;
    right: 20%;
    top: 50%;
}
.grayWave {
    position: fixed;
    z-index: -1;
    bottom: 0px;
    width: 100%;
    left: 0;
    right: 0;
}
.redBars {
    position: fixed;
    z-index: -1;
    bottom: 0px;
    right: 20px;
    display: block;
    opacity: 0;
}
.redLeftBars {
    display: none;
}
.crbMobileLogoDiv {
    text-align: center;
    margin-bottom: 2rem;
}
.crbScreenshotsDiv {
    text-align: left;
}
.crbScreenshotsImg {
    width: 80%;
}

/*letsbloom*/
.purpleBlob {
    animation-duration: 10s;
    animation-iteration-count: infinite;

    width: 75%;
    position: absolute;
    left: 0;
}
.orangeBlob {
    animation-duration: 6s;
    animation-iteration-count: infinite;

    position: absolute;
    width: 30%;
    top: 70%;
    right: 0;
}
.letsbloom3D {
    position: relative;
    width: 80%;
    top: 30%;
    margin-right: 15%;
}
.letsbloomGroupImg {
    display: none;
}
.letsbloomGroupSet {
    text-align: center;
    margin-top: 2rem;
}

/*online garage*/
.roadWave {
    position: fixed;
    z-index: -1;
    bottom: 0;
    width: 300%;
}
.aaaLogoMobile {
    width: 80%;
    max-width: 20rem;
    margin-bottom: -35px;
    margin-top: -3rem;
}
.aaaLogoDesktop {
    display: none;
}
.aaaImgSet {
    display: none;
}
.aaaImgGroup {
    width: 80%;
    margin-right: 15%;
}
.aaaImgGroupDesktop {
    display: none;
}
.aaaImagesCol {
    margin: 4rem 15%;
}




@media (min-width: 400px) {
    .hiRoadSmallScreen {
        right: 6rem;
    }

    .crbPhone {
        height: 22rem;
        margin-top: 2rem;
    }
    .crbSmallScreen {
        height: 11rem;
        margin-top: 6rem;
    }
}

@media (min-width: 500px) {
    .hiRoadSmallScreen {
        right: 8rem;
    }
    .hiRoadPhone {
        left: 5%;
    }
}

@media (min-width: 576px) {
    .yellowSquare1 {
        left: 4rem;
    }
    .yellowSquare4 {
        left: 0;
        top: 14rem;
    }
    .yellowSquare5 {
        left: 8rem;
        top: 2rem;
    }
    .bestBuyPhone1 {
        margin-top: 8rem;
        height: 20rem;
    }
    .bestBuyPhone2 {
        height: 22rem;
    }
    .bestBuyPhone3 {
        height: 24rem;
        margin: -5rem 10rem 0 10rem;
    }

    .hiRoadPhone {
        height: 36rem;
        left: 10%;
    }
    .hiRoadSmallScreen {
        height: 13rem;
        right: 6rem;
    }
    
    .crbMobileLogo {
        width: 70%;
    }
    .crbScreenshotsImg {
        width: 50%;
        margin: 0rem 20%;
    }
}
@media (min-width: 600px) {
    .bestBuyPhone3 {
        margin: -5rem 12rem 0 15rem;
    }

    .hiRoadPhone {
        height: 36rem;
        left: 3%;
    }
    .hiRoadSmallScreen {
        position: relative;
        height: 13rem;
        right: -14rem;
    }
}
@media (min-width: 768px) {
    .modalTextLeft {
        margin: 0 8rem 0px 0px;
        text-align: left;
    }
    .bestBuyHeader,
    .bestBuyLogoRow {
        display: block;
    }
    .bestBuyLogoHeader {
        display: none;
    }
    .bestBuyPhone1 {
        height: 22rem;
        position: absolute;
        left: 2rem;
        top: -3rem;
    }
    .bestBuyPhone2 {
        height: 18rem;
        position: absolute;
        left: 27%; 
    }
    .bestBuyPhone3 {
        height: 24rem;
        position: absolute;
        top: 13rem;
        left: 2%;
    }
    .yellowSquare1 {
        width: 8rem;
        position: absolute;
        left: 2rem;
        z-index: -1;
        top: 1rem;
    }
    .yellowSquare4 {
        width: 10rem;
        position: absolute;
        right: -1rem;
        bottom: -10rem;
        z-index: -1;
    }
    .yellowSquare5 {
        width: 90px;
        position: absolute;
        right: 0;
        top: 25rem;
    }

    .hiRoadMobileLogo {
        display: none;
    }
    .hiRoadDesktopLogo {
        height: 5rem;
        position: absolute;
        right: -50%;
        top: 20%;
        z-index: 1;
        display: block;
    }
    .hiRoadCircle {
        display: block;
        top: 12rem;
    }
    .hiRoadPhone {
        left: 15%;
        height: 34rem;
        position: relative;
        top: 6rem;
    }
    .hiRoadSmallScreen {
        position: absolute;
        right: -30%;
        top: 60%;
    }

    .crbMobileLogo {
        display: none;
    }
    .crbDesktopLogo {
        height: 6rem;
        display: block;
        margin: 20px 20% 0;
    }

    .purpleBlob {
        width: 85%;
        position: absolute;
        top: 0;
    }
    .letsbloom3D {
        position: relative;
        width: 75%;
        top: 30%;
    }
    .orangeBlob {
        position: absolute;
        width: 70%;
        top: 40%;
        right: 0;
    }
    .letsbloomGroupImg {
        width: 80%;
        display: block;
        margin: 0 auto;
    }
    .letsbloomGroupSet {
        display: none;
    }

    .aaaLogoMobile {
        display: none;
    }
    .aaaLogoDesktop {
        display: block;
        height: 10rem;
        position: absolute;
        left: 15%;
        top: 15%;
        z-index: 1;
    }
    .roadWave {
        width: 120%;
    }
    .aaaImgSet {
        display: block;
    }
    .aaaImgGroup {
        display: none;
    }
    .aaaImgGroupDesktop {
        width: 90%;
        display: block;
        margin: 0 auto;
    }
}

@media (min-width: 820px) {
    .bestBuyPhone3 {
        left: 5%;
    }
}

@media (min-width: 900px) {
    .hiRoadPhone {
        left: 25%;
    }
    .hiRoadCircle {
        right: -45%;
    }
}

@media (min-width: 975px) {
    .bestBuyPhone3 {
        left: 8%;
    }
}

@media (min-width: 992px) {
    .modalTextLeft {
        margin: 0 1rem 0px 0px;
    }
    .bestBuyScrolling {
        max-height: 80vh;
        overflow: scroll;
    }
    .hiRoadScrolling {
        max-height: 64vh;
        overflow: scroll;
    }
    .crbScrolling {
        max-height: 72vh;
        overflow: scroll;
    }
    .letsbloomScrolling {
        max-height: 80vh;
        overflow: scroll;
    }
    .aaaScrolling {
        max-height: 64vh;
        overflow: scroll;
    }
    .bestBuyPhone3 {
        top: 25rem;
        left: 5%;
    }

    .hiRoadPhone {
        left: -10%;
    }
    .hiRoadSmallScreen {
        left: 45%;
        top: 25%;
    }
    .greenWave {
        position: fixed;
        top: 50%;
    }
    .hiRoadDesktopLogo {
        right: 3%;
        top: 2%;
    }
    .hiRoadCircle {
        right: 0%;
        height: 500px;
        width: 500px;
        top: 7rem;
    }
    .hiRoadGraphics {
        margin: 0 2rem;
    }

    .crbPhone {
        height: 35rem;
        float: left;
        position: absolute;
        z-index: 1;
        left: 20%;
        top: 20%;
        border-radius: 10px;
        box-shadow: 0 0 10px black;
    }
    .crbSmallScreen {
        height: 15rem;
        float: left;
        position: absolute;
        z-index: 1;
        right: 15%;
        top: 50%;
    }
    .redBars {
        position: fixed;
        z-index: -1;
        bottom: 0px;
        right: 20px;
        display: block;
        opacity: 1;
    }

    .crbDesktopLogo {
        margin: 2rem auto;
        display: block;
    }
    .crbScreenshotsImg {
        width: 70%;
        /* margin: 2rem auto; */
    }

    .letsbloomGroupImg {
        display: none;
    }
    .letsbloomGroupSet {
        display: block;
    }
    .aaaImagesCol {
        margin: 1rem 0 0 0;
    }
}

@media (min-width: 1440px) {
    .bestBuyPhone1 {
        left: 6rem;
        height: 24rem;
    }
    .bestBuyPhone2 {
        left: -10%;
        height: 20rem;
    }
    .bestBuyPhone3 {
        margin: 0;
        right: 0;
        top: 3rem;
        left: 75%;
        height: 24rem;
    }

    .hiRoadDesktopLogo {
        right: 3%;
        top: 10%;
    }
    .hiRoadGraphics {
        margin: 0 4rem;
    }
    .hiRoadCircle {
        right: 15%;
        height: 600px;
        width: 600px;
    }

    .redLeftBars {
        position: absolute;
        top: 9rem;
        opacity: .3;
        display: block;
    }
}

@media (min-width: 1600px) {
    .hiRoadGraphics {
        margin: 0 9rem;
    }
}