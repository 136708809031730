.navbar {
    max-width: 1920px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(30px);
    background: linear-gradient(180deg, rgba(5,19,26,1) 0%, rgba(5,19,26,0.8) 50%, rgba(5,19,26,0) 100%);
    z-index: 9;
}
.brLogoButton {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}
.mobileNavItems {
    text-align: right;
    right: 0;
    position: absolute;
}
.desktopNavItems {
    text-align: right;
    right: 0;
    position: absolute;
    display: none;
}
.navBrand {
    background-color: transparent;
    border: none;
    font-family: Avenir;
    font-weight: 600;
    font-size: 18px;
    color: white;
    display: none;
}
.navButton {
    background-color: transparent;
    border: none;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    color: white;
    padding: 0 20px;
    
}
.navButton:hover {
    color: #2DBC9D;
    transition: all .7s cubic-bezier(.25,.8,.25,1);
}

/*mobile nav overlay*/
.mobileNavOverlay {
    height: 0;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    background-image: linear-gradient(to bottom left, #560799, #1D699F, #00CCA5); /* Black fallback color */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s;
}
.mobileNavExit {
    height: 20px;
    right: 20px;
    top: 2rem;
    position: absolute;
}
.mobile-overlay-content {
    position: relative;
    top: 25%; 
    width: 100%; 
    text-align: center;
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}
.mobileNavButton {
    background: transparent;
    border: none;
    font-family: Oswald;
    font-size: 30px;
    font-weight: 600;
    color: white;
    margin-bottom: 30px;
}
.mobileNavButton:active,
.mobileNavButton:focus {
    outline: 0;
    opacity: .7;
}
@media (min-width: 768px) {
    .mobileNavItems {
        display: none;
    }
    .desktopNavItems {
        display: block;
    }
    .navbarRow {
        margin: 0 5%;
    }
    .navBrand {
        display: inline;
    }
}
