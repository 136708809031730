.mainLanding {
    background-image: url("../images/blob.svg");
    z-index: -1;
}
.upButton {
    position: fixed;
    background: transparent;
    bottom: 0;
    right: 10px;
    animation: fadeIn 1s;
    transition: opacity 1s;
    opacity: 1;
    border: none;
    position: fixed;
    bottom: 0;
    right: 25;
}
.upButton:hover{
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}