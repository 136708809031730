.capabilitiesBanner {
    margin: 10% 10px 10% 10px;
}
.capabilitiesHeader {
    margin: 40px 0px 40px 0px;
}
.aboutUsBtnCol {
    text-align: center;
}
.videoBorderOuter {
    border-left: 2px solid #FA709E;
    height: 21rem;
    border-bottom: 2px solid #FFBA00;
    padding-top: 11px;
}
.videoBorderInner {
    border-left: 2px solid #FA709E;
    border-bottom: 2px solid #FFBA00;
    height: 20rem;
    margin-left: 10px;
}
.mobileCapabilitiesContainer {
    height: 15rem;
    overflow: scroll;
}
.mobileCapabilitiesContainer:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    /* background-image: linear-gradient(to bottom, rgba(255,255,255,0), #05131A 90%); */
    width: 100%;
    height: 4em;
}
@media (min-width: 768px) {
    .capabilitiesBanner {
        margin: 10%;
    }
    .aboutUsBtnCol {
        text-align: left;
    }
}