.footerDivText {
    margin: 0px 10% 0px 10%;
}
#map {
    height: 100%;
}
.hoverOpacity:hover {
    opacity: .7;
    cursor: pointer;
}
.footerText {
    font-size: 16px;
    margin-bottom: 0;
    color: #FBFBFB;
}
.footerSubText {
    font-size: 12px;
    margin-bottom: 0;
    color: #a6e6d2;
}

@media (min-width: 992px) {
    .smallFooterImageText {
        padding-left: 20px;
    }
}