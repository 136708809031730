body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #05131A;
  color: white;
  background-repeat: no-repeat;
  background-position: 40% 9%;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: Avenir, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-expand>.container {
  flex-wrap: wrap;
}
/*fonts*/
h1 {
  font-family: Oswald;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 8px;
}
h2 {
  font-family: Oswald;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 8px;
}
h3 {
  font-family: Avenir;
  font-weight: 600;
  font-size: 36px;
}
h4 {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
}
h5 {
  font-family: Oswald;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 12px;
}
h6 {
  font-family: Oswald;
  font-size: 16px;
  font-weight: 600;
}
p {
  font-family: Avenir;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
}
li {
  font-family: Avenir;
}
a:hover {
  text-decoration: none;
}
a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
  color: initial;
}
/*buttons*/
button:active,
button:focus {
  outline: 0;
}
.whiteOutlineBtn {
  border: 1px solid white;
  padding: 15px 30px;
  font-family: Oswald;
  font-weight: 300;
  font-size: 20px;
  background: transparent;
  color: white;
  border-radius: 6px;
  letter-spacing: 3px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  animation: 1s ease-out 0s 1 fadeIn;
}
.whiteOutlineBtn:hover {
  background: #936dd8;
  border: 1px solid #936dd8;
  color: white;
  box-shadow: 0 0 10px #0000001f, 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.4s cubic-bezier(.25,.8,.25,1);
}
.whiteOutlineBtn:active,
.whiteOutlineBtn:focus {
  background: #936dd8;
  border: 1px solid #936dd8;
  color: white;
  outline: 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.invisibleButton {
  background-color: transparent;
  border: none;
}
.purpleOutlineBtn {
  border: 1px solid #B183FC;
  color: #B183FC;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*divs*/
.desktopDiv {
  display: none;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
::-webkit-scrollbar {
  width: 0;  
  background: transparent;  
}

@media (min-width: 768px) {
  h1 {
    letter-spacing: 12px;
  }

}

@media (min-width: 992px) {
  .mobileDiv {
    display: none;
  }
  .desktopDiv {
    display: block;
  }
}

/*imgs*/
.planetoidFloat {
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

@media (min-width: 1200px) {
  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 48px;
  }
  h4 {
    font-size: 24px;
  }
  p {
    font-size: 18px;
    letter-spacing: 2px;
  }
}

@media (min-width: 1600px) {
  h1 {
    font-size: 80px;
  }
  h4 {
    font-size: 28px;
  }
  .whiteOutlineBtn {
    font-size: 28px;
  }
  .heroBanner {
    margin: 10% 20% 15% 20%;
  }
}