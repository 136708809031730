.meetCrewBanner {
    margin: 10% 10px;
}
.profileBlurb {
    font-size: 12px;
    letter-spacing: .5px;
}
.meetCrewImg {
    height: 6rem;
    margin: -1px;
}
.meetCrewImg:hover {
    opacity: .7;
    cursor: pointer;
}
@media (min-width: 768px) {
    .meetCrewBanner {
        margin: 0px 10% 10% 10%;
    }
}