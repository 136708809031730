
.projectsSelectorRow {
    border: 1px solid #0D5B69;
    border-radius: 7px;
}
.notSelected {
    cursor: pointer;
}
.selected {
    background-color: #6075D0;
    border-radius: 6px;
    color: black;
    box-shadow: 3px 3px 6px #1b1b1b;
    cursor: pointer;
}
.slideLeft {
    animation: .4s ease-out 0s 1 slideBtnLeft;
}
.slideRight {
    animation: .4s ease-out 0s 1 slideBtnRight;
}
@keyframes slideBtnLeft {
    0% {
      opacity: 0;
      transform: translateX(10px)
    }
    100% {
      opacity: 1;
      transform: translateX(0px)
    }
}
@keyframes slideBtnRight {
    0% {
      opacity: 0;
      transform: translateX(-10px)
    }
    100% {
      opacity: 1;
      transform: translateX(0px)
    }
}

.servicesTab {
    background-color: #2DBC9D;
}
.projectsList {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.projectsBlurb {
    color: #6075D0;
}
.servicesFont {
    color: #a584ce;
}
.industriesFont {
    color: #54c0bf;
}
.desktopTabContent {
    display: none;
}
.marginTen {
    margin-left: 10%;
    margin-right: 10%;
}
.clientsHeaderRow {
    margin: 0 10px 0px 10px
}
.projectsRow {
    margin-left: 10px;
    margin-right: 10px;
}

/*mobile view*/
.clientsCard {
    backdrop-filter: blur(30px);
    background: transparent linear-gradient(144deg,#98aae80f,#98aae866) 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 10px;
    width: 30rem;
}
.gallery {
  position: relative;
  padding: 0 10;
}
.gallery_scroller {
   /* snap mandatory on horizontal axis  */
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  align-items: center;
    -webkit-overflow-scrolling: touch;
}
.gallery_scroller div {
  scroll-snap-align: center;
  margin: 10px;
  position: relative;
  width: 13rem;
  height: 12rem;
}
@media (min-width: 768px) {
    .clientsHeaderRow {
        margin: 0 10%;
    }
    .projectsRow {
        margin-left: 10%;
        margin-right: 10%;
    }
    .mobileTabContent {
        display: none;
    }
    .desktopTabContent {
        display: block;
    }
}
@media (min-width: 992px) {
    .projectsList {
        font-size: 36px;
    }

}