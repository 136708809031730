.featuredBanner {
    margin: 5% 10px;
}
.featuredProjectsText {
    text-align: center;
}
.featuredList {
    font-size: 24px;
    font-family: Avenir;
    list-style: none;
    padding: 0 0 0 10px;
    border-left-width: 2px;
    border-right-width: 0;
    border-style: solid;
    border-image: 
    linear-gradient(
      to bottom, 
      #6B3C6C, 
      #320459
    ) 1 100%;
}
.featuredListName {
    margin: 10px 0 10px 0px;
    text-align: left;
    width: 100%;
    color: #48A8F9;
}
.featuredListName:hover {
    color: #d783fc;
}
.selectedFeature {
    color: #d783fc;
    margin-left: 10px;
    outline: 0;
}
.featuredListName:focus,
.featuredListName:active,
.selectedFeature:focus,
.selectedFeature:active {
    outline: 0;
}
.featuredSummaryBox {
    text-align: center;
}

/*Featured Mobile Carousel*/
.featuredMobileCarouselName {
    font-size: 24px;
    font-family: Avenir;
    text-transform: uppercase;
    font-weight: 300;
    color: #F54B83;
    background-color: transparent;
    border: none;
}
.arrowLeftTurquoise {
    position: absolute;
    left:  0;
    bottom: 16px;
}
.arrowRightTurquoise {
    position: absolute;
    right:  0;
    bottom: 16px;
}
.arrowLeftTurqBtn:focus,
.arrowRightTurqBtn:focus {
    outline: 0;
}
.desktopFeaturedCarouselDiv {
    display: none;
}

/*modal details*/
.modalHeader {
    font-family: Avenir;
    font-size: 36px;
    letter-spacing: 4px;
    margin-bottom: 30px;
    display: none;
}
.modalSubHeader {
    font-family: Avenir;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 1px;
}

/* The Overlay (background) */
.overlay {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: black;
    overflow-x: hidden; 
    transition: 0.5s;
}
.overlay0 {
    background-color: #052E46;
}
.overlay1 {
    background-color: #1E303D;
}
.overlay2 {
    background-color: #191919;
}
.overlay3 {
    background-color: black;
}
.overlay4 {
    background-color: #002D5D;
}
  
  /* Position the content inside the overlay */
.overlay-content {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0rem 0px 5rem 3rem;
    max-width: 1920px;
}
  
  /* The navigation links inside the overlay */
.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
}
  
/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}
  
.overlay .scrollUp {
    position: sticky;
    top: 15px;
    left: 10px;
}
.overlay .scrollDown {
    position: sticky;
    top: 91%;
    left: 10px;
}
.overlay .closebtn {
    position: sticky;
    top: 15px;
    float: right;
}

  /* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }

  @media (min-width: 768px) {
    .modalHeader {
        display: block;
    }
    .featuredBanner {
        margin: 5% 10%;
    }
    .mobileFeaturedCarouselDiv {
        display: none;
    }
    .desktopFeaturedCarouselDiv {
        display: block;
    }
    .featuredSummaryBox {
        backdrop-filter: blur(30px);
        background: transparent linear-gradient(144deg,#98aae80f,#98aae866) 0 0 no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .featuredProjectsText {
        text-align: left;
    }

    .overlay .scrollUp {
        top: 20px;
        left: 45px;
    }
    .overlay .scrollDown {
        bottom: 20px;
        left: 45px;
    }
    .overlay .closebtn {
        top: 20px;
        right: 45px;
    }
    .learnMoreDiv {
        margin-top: auto;
        padding: 30px;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }
  }